/* nug element styles */

.masthead-root {
    padding: 6px 30px;
}

.nug-masthead {
    position: relative;
    background-color: #f0f0f0;
    height: auto;
    font-size: 14px
}

.nug-masthead .nug-icon {
    font-size: 20px
}

.nug-masthead a {
    color: #484848;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.nug-masthead .is-text {
    margin-left: 4px;
}

.nug-masthead a:hover {
    color: #151515
}

/* Transition */
body {
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-family: 'IBM Plex Sans', sans-serif;
    overflow-wrap: break-word;
    /* Do not use default window margins. */
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    min-height: 100vh;
    background: var(--transition-body-color);
}

#main-message p {
    line-height: 2rem;
}

.top-half {
    background: var(--transition-top-half-color);
    height: 50vh;
    position: relative;
}

.top-half-content {
    text-align: center;
    color: var(--transition-top-half-content-color);
    position: absolute;
    bottom: -56px;
    left: 50%;
    transform: translateX(-50%);
}

.top-half-content h3 {
    font-weight: 600;
    margin-bottom: 6px;
}

.top-half-content a {
    display: block;
    color: var(--transition-top-half-content-link-color);
    font-size: 16px;
    margin-bottom: 35px;
}

.browser-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 433px;
    height: 140px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
}

.links {
    width: 100%;
    height: 140px;
    padding-top: 75px;
    padding-left: 167px;
    color: var(--transition-links-color);
}

.links-health {
    padding-top: 77px;
    padding-left: 167px;
}

.links-edu {
    padding-top: 77px;
    padding-left: 172px;
}

.bottom-half {
    text-align: center;
    min-height: 250px;
}

.bottom-half>p {
    margin-top: 45px;
    font-size: 13px;
}

#skip {
    padding-top: 50px;
    opacity: 1;
    display: block;
    cursor: pointer;
}

#skip>span {
    font-size: 13px;
    color: var(--transition-skip-span-color);
    text-decoration: underline;
}

.footer {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
}

.footer p {
    color: var(--transition-footer-p-color);
    font-size: 13px;
    line-height: 13px;
}

.mobile-break {
    display: none;
}

#url {
    font-size: 13px;
    display: inline;
}

#logo {
    padding-bottom: 20px;
}

#spinner {
    height: 10px;
}

@media only screen and (max-width: 768px) {
    .links {
        padding-top: 71px;
        padding-left: 134px;
    }

    .links-health {
        padding-top: 73px;
        padding-left: 133px;
    }

    .links-edu {
        padding-top: 74px;
        padding-left: 139px;
    }

    .browser-image {
        width: 350px;
    }
}

@media only screen and (max-width: 512px) {
    .top-half {
        height: 35vh;
    }

    .passCard {
        padding: 2rem !important;
    }

    .top-half-content {
        bottom: -58px;
        width: 80%;
    }

    .top-half-content h3 {
        margin-bottom: 12px;
        font-size: 22px;
    }

    .top-half-content a {
        font-size: 14px;
        margin-bottom: 0;
    }

    .browser-image {
        width: 300px;
    }

    .links {
        padding-top: 73px;
        padding-left: 116px;
        font-size: 12px;
        height: 120px;
        box-sizing: border-box;
    }

    .links-health {
        padding-top: 74px;
        padding-left: 114px;
    }

    .links-edu {
        padding-top: 75px;
        padding-left: 119px;
    }

    .top-half-content a {
        line-height: 20px;
    }

    .footer {
        bottom: 30px;
    }

    .footer img {
        margin-bottom: 24px;
    }

    .mobile-break {
        display: block;
    }
}

@media only screen and (max-width: 374px) {
    .top-half-content {
        width: 100%;
    }

    .top-half-content h3 {
        margin-bottom: 0;
        margin-left: 8px;
        margin-right: 8px;
    }

    .top-half-content a {
        margin-left: 8px;
        margin-right: 8px;
    }

    .browser-image {
        width: 300px;
    }

    .passCard {
        padding: 2rem !important;
    }
}

.passcontainer {
    height: 100vh;
}

.formwidth {
    width: 30rem;
    display: block !important;
}

.passtitle {
    align-self: center;
    margin-left: 1rem;
}

.redirectLink {
    display: flex;
    flex-direction: row-reverse;
    padding: 0.2rem 0.5rem;
    color: gray;
    font-size: small;
    align-self: center;
    background: #e5e5e5;
    border-radius: 0.4rem;
    width: fit-content;
}

.protectTitle {
    font-weight: 800;
}

.smallinfo {
    font-size: smaller;
}

.passCard {
    padding: 3rem;
    border-radius: 0.5rem;
    /* background: #e5e5e5; */
}